/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import Button from '../components/Button';
import FullHeightColumn from '../components/FullHeightColumn';
import { InfoIcon } from '../components/Icon';
import Tooltip from '../components/Tooltip';
import { goNext, ParcelType, selectParcelType } from '../state/parcelFlow';
import { locNavigate } from '../state/session';
import { DomesticParcelIcon, ExpressParcelIcon, InternationalParcelIcon, MultiParcelIcon2 } from '../components/Icon';

const ItemCard = ({ item, isSelected, disabled }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const strId = `buyParcel.type.${item.type}`;

  return (
    <Flex
      sx={{
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 160,
        height: 226,
        py: 4,
        px: 2,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: isSelected ? 'primary' : 'border',
        borderRadius: 1,
        color: isSelected ? 'white' : disabled ? 'grayLight' : 'inherit',
        backgroundColor: isSelected ? 'primary' : 'white',
        ':hover': {
          borderColor: isSelected ? 'primary' : disabled ? null : 'grayLight',
          cursor: disabled ? '' : 'pointer',
        },
        img: {
          filter: isSelected
            ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(138deg) brightness(99%) contrast(103%)'
            : 'none',
        },
      }}
    >
      <Flex
        sx={{
          flex: 'none',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          px: 2,
        }}
      >
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {item.icon()}
        </Flex>
        <Box
          sx={{
            fontSize: 2,
            fontWeight: 'medium',
            textAlign: 'center',
            lineHeight: 1.3,
            mt: 24,
            flexGrow: 1,
          }}
        >
          {translate(strId)}
        </Box>
      </Flex>
    </Flex>
  );
};

const ItemList = ({ items, selectedType }) => {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const container = useRef();
  const [focusIndex, setFocusIndex] = useState(0);

  const onKeyDown = useCallback(
    e => {
      let elements = [];
      if (container.current) {
        elements = Array.from(container.current.querySelectorAll('.product'));
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          setFocusIndex(i => {
            const newIndex = i > 0 ? i - 1 : items.length - 1;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          setFocusIndex(i => {
            const newIndex = i < items.length - 1 ? i + 1 : 0;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case ' ':
        case 'Enter':
          setFocusIndex(i => {
            const item = items[i];
            if (item) {
              setImmediate(() => {
                item.disabled ? null : dispatch(selectParcelType(item.type));
              });
            }
            return i;
          });
          e.preventDefault();
          break;
      }
    },
    [items, setFocusIndex, dispatch]
  );

  return (
    <Flex
      ref={container}
      sx={{
        flexWrap: ['nowrap', null, 'wrap'],
        overflowX: ['auto', null, 'visible'],
        mb: 3,
        mx: [-3, null, 0],
      }}
      role="radiogroup"
      aria-label={translate('buyParcel.type.title')}
      onKeyDown={onKeyDown}
    >
      {items.map((item, index) => (
        <Box
          key={item.type}
          onClick={() => (item.disabled ? null : dispatch(selectParcelType(item.type)))}
          sx={{
            flex: 'none',
            mr: 2,
            mb: 2,
            ':first-of-type': {
              pl: [3, null, 0],
            },
            ':last-of-type': {
              pr: 3,
            },
          }}
          className="product"
          role="radio"
          aria-checked={item.type === selectedType}
          aria-disabled={!!item.disabled}
          tabIndex={focusIndex === index ? 0 : -1}
        >
          <ItemCard item={item} isSelected={item.type === selectedType} disabled={item.disabled} />
        </Box>
      ))}
    </Flex>
  );
};

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const parcelType = useSelector(state => state.parcelFlow.parcelType);

  const alreadyInBasket = useSelector(state => !!state.parcelFlow.rowId);
  const backToShoppingBasket = useCallback(() => {
    dispatch(locNavigate('/ostoskori'));
  }, [dispatch]);

  const preventDoubleClick = useRef(false);
  const onNextClick = useCallback(() => {
    if (preventDoubleClick.current) {
      return;
    }
    preventDoubleClick.current = true;
    try {
      dispatch(goNext());
    } finally {
      preventDoubleClick.current = false;
    }
  }, [dispatch]);

  const continueDisabled = !parcelType;

  const nonExpressParcelsInBasket = useSelector(state =>
    (state.basket.items || []).some(item => item.parcelType !== ParcelType.Express)
  );
  const items = [
    { type: ParcelType.Domestic, icon: DomesticParcelIcon },
    { type: ParcelType.Abroad, icon: InternationalParcelIcon },
    { type: ParcelType.Express, icon: ExpressParcelIcon, disabled: nonExpressParcelsInBasket },
    { type: ParcelType.MultiParcel, icon: MultiParcelIcon2 },
  ];

  const description = translate(`buyParcel.type.${parcelType}.description`, {}, { onMissingTranslation: () => '' });
  const expressTooltip = useMemo(() => {
    const lines = [];
    let line;
    let index = 0;
    do {
      index += 1;
      line = translate('buyParcel.express.tooltip.' + index, {}, { onMissingTranslation: () => '' });
      if (line) lines.push(line);
    } while (line && index < 10);
    lines.push(translate('buyParcel.express.tooltip.end'));
    return lines;
  }, [translate]);

  return (
    <FullHeightColumn>
      <Box>
        {alreadyInBasket ? (
          <Button onClick={backToShoppingBasket} variant="plain" sx={{ color: 'primary' }}>
            {translate('buyParcel.backToBasket')}
          </Button>
        ) : null}
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('buyParcel.type.title')}</Styled.h1>

      <Flex
        sx={{
          flex: 'auto',
          flexDirection: 'column',
          justifyContent: ['space-between', null, null, 'flex-start'],
        }}
      >
        <ItemList items={items} selectedType={parcelType} />
        {description && <Box sx={{ mb: [3], maxWidth: 800 }}>{description}</Box>}
        <Flex
          sx={{
            mt: [4],
            flexDirection: ['row-reverse', null, 'row'],
            justifyContent: 'space-between',
          }}
        >
          <Button
            sx={{ alignSelf: ['flex-end', null, 'flex-start'], flex: 'none' }}
            onClick={onNextClick}
            disabled={continueDisabled}
          >
            {translate('buyParcel.continue')}
          </Button>
        </Flex>
      </Flex>
    </FullHeightColumn>
  );
};
