/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate } from 'react-localize-redux';

import Layout from '../components/layout';
import Container from '../components/Container';
import { getProducts } from '../state/catalog';
import { PAGE, buySeriesShipment, resetParcelFlow } from '../state/parcelFlow';
import ExtraServices from '../parcelFlow/ExtraServices';
import ParcelProduct from '../parcelFlow/ParcelProduct';
import ParcelType from '../parcelFlow/ParcelType';
import { isBrowser } from '../utils';
import * as analytics from '../utils/analytics';

let latestInstance = 0;

class BuySeriesParcel extends Component {
  constructor(props) {
    super(props);
    latestInstance++;
    this.instanceNo = latestInstance;

    const { products } = props.catalog;
    if (isBrowser && (!Array.isArray(products) || products.length === 0)) {
      this.props.getProducts();
    }

    this.props.buySeriesShipment(true);
  }

  componentWillUnmount() {
    // if user changes language, new component is constructed before this method is called
    if (this.instanceNo === latestInstance) {
      this.props.resetParcelFlow();
    }
  }

  render() {
    analytics.setPageCategory(this, 'paketit');
    const { page, translate } = this.props;
    const { paths } = this.props.pageContext;
    return (
      <Layout title={translate('seriesWidget.title')} paths={paths} locale={this.props.pageContext.locale || 'en'}>
        <Container variant="parcel">
          {(() => {
            switch (page) {
              case PAGE.PARCEL_TYPE:
                return <ParcelType />;
              case PAGE.SELECT_COUNTRY:
              case PAGE.SELECT_PRODUCT:
                return <ParcelProduct />;
              case PAGE.SELECT_EXTRAS:
                return <ExtraServices />;
              default:
                return <h1>Virhetilanne</h1>;
            }
          })()}
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  catalog: state.catalog || {},
  page: (state.parcelFlow || {}).page,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      buySeriesShipment,
      getProducts,
      resetParcelFlow,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BuySeriesParcel);
